<template>
    <div class="container" :style="{background: bgList[bg]}">
        <div class="header">
            <van-icon name="cross" size="16" color="#000" class="close" @click="$router.push({path: '/self/service/bill/banklist/bank/auth'})" />
        </div>
        <div v-if="showFace">
            <div class="title">验证后将展示银行卡号完整卡号</div>
            <div class="tip" :class="{tip1: showTip1}" v-if="!closeTip1">请保持脸在检测框内</div>
            <div class="tip" :class="{tip2: showTip2}" v-if="closeTip1 && !closeTip2">请眨眨眼</div>
            <div class="tip" v-if="showTip3 && !showTip4">屏幕即将闪烁，请保持不动</div>
            <div class="tip" v-if="showTip4">请稍后</div>
            <div class="place" v-if="closeTip1 && closeTip2 && !showTip3 && !showTip4"></div>
            <video class="vi" id="video-1"></video>
            <div class="mask" :class="maskStyle[bg]">
                <van-circle v-model="currentRate" :rate="0" :color="circleColor"  layer-color="rgba(0,0,0,0)"  size="140px" />
            </div>
            <div class="mask-loading" v-if="showTip4">
                <van-loading color="ccc" size="28" />
            </div>
        </div>
        <div v-else class="success">
            <div>已通过</div>
            <div class="icon">
                <van-icon name="success" color="#fff" size="30" />
            </div>
        </div>
    </div>


</template>

<script>
import {
  BrowserMultiFormatReader,
  ChecksumException,
  FormatException,
} from "@zxing/library";

export default {
    data() {
        return {
            codeReader: new BrowserMultiFormatReader(),
            videoInputDevices: [],
            currentVideoInputDevice: {},
            decodeResult: undefined,
            scanSuccess: false,
            showFace: true,
            showTip1: false,
            closeTip1: false,
            showTip2: false,
            closeTip2: false,
            showTip3: false,
            bgList: ['#fff', '#1111f5', '#e11e1e', '#5a5a5a'],
            bg: 0,
            maskStyle: ['mask1', 'mask2', 'mask3', 'mask4'],
            currentRate: 0,
            showTip4: false,

        }
    },
    computed: {
        circleColor() {
            return (!this.showTip3 || this.showTip4) ? 'rgba(0,0,0,0)' : '#fff'
        }
    },
    mounted: function () {
        this.openScan();
        setTimeout(() => {
            this.successDecode();
        }, 2000)
    },
    unmounted: function () {
        this.codeReader.reset(); //关闭摄像头
    },
    methods: {
        async openScan() {
            const _this = this;
            _this.codeReader
                .getVideoInputDevices() //老版本listVideoInputDevices()
                .then((videoInputDevices) => {
                if (videoInputDevices && videoInputDevices.length) {
                    // if (videoInputDevices.length > 1) {
                    //     videoInputDevices.reverse();
                    // } //防止先唤出前摄像头
                    _this.videoInputDevices = videoInputDevices;
                    _this.currentVideoInputDevice = videoInputDevices[0];
                }
            })
            .catch(() => {});
        },
        decodeFromInputVideo() {
            const _this = this;
            _this.codeReader.reset();
            // 多次
            _this.codeReader.decodeFromVideoDevice(
                _this.currentVideoInputDevice.deviceId,
                "video-1",
                (result, err) => {
                    if (result) {
                        _this.decodeResult = result;
                    }
                    if (err) {
                        if (err instanceof ChecksumException) {
                            alert(1);
                            console.log(
                                "A code was found, but it's read value was not valid."
                            );
                        }
                        if (err instanceof FormatException) {
                            alert(2);
                            console.log("A code was found, but it was in a invalid format.");
                        }
                    }
                }
            );
        },
        successDecode() {
            let timer
            setTimeout(() => {
                this.showTip1 = true
            }, 2000)
            setTimeout(() => {
                this.closeTip1 = true
            }, 3600)
            setTimeout(() => {
                this.showTip2 = true
            }, 5000)
            setTimeout(() => {
                this.closeTip2 = true
                this.showTip3 = true
                timer = setInterval(() => {
                    if (this.bg == 3) {
                        this.bg = 0
                        this.currentRate += 20
                    } else {
                        this.currentRate += 20
                        this.bg++
                    }
                }, 1000);
            }, 7000)
            setTimeout(() => {
                clearInterval(timer)
                this.bg = 0
                this.showTip4 = true
            }, 15000)
            setTimeout(() => {
                this.showFace = false
            }, 18000);
            setTimeout(() => {
                this.$router.push({ path: '/self/service/bill/banklist/bank', query: { auth: true } })
            }, 19000)

        },
    },
    watch: {
        currentVideoInputDevice: function () {
            this.decodeFromInputVideo();
        },
        decodeResult: function () {
           
        },
  },
}

</script>

<style lang="less" scoped>
.container {
    background: #fff;
}
.header {
    .van-icon {
        margin-left: 10px;
        margin-top: 10px;
    }
}
.title {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    margin-top: 40px;
}
.tip {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
}
.place {
    height: 36px;
}
.v-con {
    width: 100%;
    height: 400px;
}
.vi {
    width: 200px;
    height: 200px;
    margin-top: 40px;
    margin-left: calc(~"50% - 100px");
}
.mask {
    position: absolute;
    width: 100%;
    height: 210px;
    top: 174px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 70px,
        #fff 70px
    );
}
.mask-loading {
    position: absolute;
    width: 100%;
    height: 210px;
    top: 176px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mask1 {
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 70px,
        #fff 70px
    )
}
.mask2 {
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 70px,
        #1111f5 70px
    )
}
.mask3 {
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 70px,
        #e11e1e 70px
    )
}
.mask4 {
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0) 70px,
        #5a5a5a 70px
    )
}
.tip1 {
    animation: transformLeft 2s;
}
.tip2 {
    animation: transformLeft 2s;
}
@keyframes transformLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    font-size: 20px;
    .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #2BAC69;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        animation: zoom 2s;
    }
}
@keyframes zoom {
  0% {
    width: 50px;
    height: 50px;
  }
  100% {
    width: 70px;
    height: 70px;
  }
}

</style>